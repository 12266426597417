<template>
  <div
    class="relative h-full flex-1"
    :class="{ 'overflow-hidden': roomsStore.loading }"
  >
    <common-room-parts-list-header
      :is-list="isList"
      :show-filter="showFilter"
      @toggle-filter="toggleShowFilter"
      @toggle-view="toggleIsList"
    />
    <div class="mb-6">
      <div class="xl:hidden">
        <ui-fields-text
          icon-right="search"
          name="search"
          placeholder="Find the room"
        />
      </div>
      <transition name="fade">
        <common-room-parts-filter
          v-if="showFilter"
          @close="toggleShowFilter(false)"
          @update="onSetValuesAndFetch"
        />
      </transition>
    </div>
    <ui-pagination-with-loader
      :items="roomsStore.rooms"
      :navigation="roomsStore.meta"
      :wrap-class="wrapClass"
      :static="roomsStore.loading && !roomsStore.rooms.length"
      :loading="roomsStore.loading"
      :error="error"
      @set-page="setFieldValue('cursor', $event)"
    >
      <template #item="{ item, index }">
        <common-room-parts-card
          :key="index"
          :source="item"
          :show-online="isRoom"
        />
      </template>
      <template #content-empty>
        <common-room-parts-empty-search>
          <ui-button
            v-if="isApplyFilter"
            variant="link"
            text="Reset filter"
            @click="onResetFilter"
          />
        </common-room-parts-empty-search>
      </template>
    </ui-pagination-with-loader>
  </div>
</template>

<script setup lang="ts">
  definePageMeta({
    layout: 'room'
  });

  const [showFilter, toggleShowFilter] = useToggle();
  const [isList, toggleIsList] = useToggle();

  const wrapClass = computed(() => {
    const currentView = isList.value
      ? 'grid-cols-1 sm:grid-cols-2 md:grid-cols-2'
      : 'grid-cols-2 sm:grid-cols-3 md:grid-cols-4';
    return ['grid lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 lg:gap-7', currentView];
  });

  const initialValues = {
    cursor: '',
    search: '',
    type: String(RoomType.Room),
    interest_ids: []
  };

  const { onResetScroll } = useLayoutWrap();
  const roomsStore = useRoomsStore();

  const { values, setFieldValue, isApplyFilter, onResetFilter, onSetValuesAndFetch } = useFiltersQuery({
    formOptions: {
      initialValues
    },
    onBeforeSetQueryItems(val, prevVal) {
      onResetScroll();
      if (val.cursor === prevVal.cursor) {
        return {
          cursor: ''
        };
      }
    },
    prepareTypedObj(val) {
      val.interest_ids = useMap(val.interest_ids, Number);
      return val;
    },
    onLoadingData: () => {
      roomsStore.fetchRooms(values);
    }
  });

  const { error } = await useAsyncData('rooms', async () => {
    const res = await roomsStore.fetchRooms(values);
    if (res.isError) {
      throw res.response;
    }
    return true;
  });

  const isRoom = computed(() => Number(values.type) === RoomType.Room);

  useWatchDisconnect({
    online() {
      if (!roomsStore.loading) {
        roomsStore.fetchRooms(values, true);
      }
    }
  });

  onUnmounted(() => {
    roomsStore.reset();
  });

  useSeoMeta({
    title: 'Search Room'
  });
</script>
