<template>
  <div
    class="group/field"
    :class="[{ 'field-error': error }, filedWrapClass]"
  >
    <ui-fields-label
      v-if="labelTwo"
      :text="labelTwo"
      :required="required"
      :error="!!error"
    />
    <div
      :class="wrapClasses"
      :data-ui="dataUi"
      @mousedown="onControlMousedown"
    >
      <button
        v-if="$slots['icon-left'] || iconLeft"
        type="button"
        :class="iconClasses"
        @click="onClickLeftIcon"
      >
        <slot name="icon-left">
          <ui-icon
            v-if="iconLeft"
            :name="iconLeft"
          />
        </slot>
      </button>
      <div :class="inputWrapClasses">
        <slot
          :set-ref="setRef"
          :input-id="currentId"
          :input-classes="inputClasses"
          :on-focus="onFocus"
          :on-blur="blur"
        />
        <ui-fields-label
          v-if="label"
          :for="currentId"
          class="order-1"
          :focus="isFocus"
          :placeholder-show="hasValue"
          :required="required"
          :text="label"
          floating
          :floating-top="floatingTop"
          :error="!!error"
        />
      </div>
      <button
        v-if="showClear && !loading"
        type="button"
        :class="iconClasses"
        @click="onClickClose"
      >
        <ui-icon name="close" />
      </button>
      <span
        v-if="loading"
        :class="iconClasses"
      >
        <ui-spinner />
      </span>
      <button
        v-if="$slots['icon-right'] || iconRight"
        type="button"
        :class="iconClasses"
        @click="onClickRightIcon"
      >
        <slot name="icon-right">
          <ui-icon
            v-if="iconRight"
            :name="iconRight"
          />
        </slot>
      </button>
    </div>

    <div
      v-if="showError || hint || $slots.hint"
      class="md:pl-8 pl-5"
    >
      <ui-typography
        v-if="showError"
        variant="small"
        class="mb-1.5 text-error animate-shake"
      >
        {{ error }}
      </ui-typography>
      <slot name="hint">
        <ui-typography
          v-if="hint"
          variant="small"
        >
          {{ $t(hint || '') }}
        </ui-typography>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { cx } from 'class-variance-authority';
  const props = withDefaults(defineProps<FieldWrapProps>(), {
    autofocus: false,
    disabledControlledMouseDown: false
  });

  const uid = useId();
  const currentId = computed(() => props.inputId || uid);

  const emit = defineEmits<{
    (e: 'focus' | 'blur'): boolean;
    (e: 'mousedown:control', value: MouseEvent): boolean;
  }>();

  const isFocus = ref(false);
  const inputRef = ref<HTMLInputElement>();
  const dataUi = computed(() =>
    cx({
      focus: isFocus.value,
      error: props.error,
      disabled: props.disabled
    })
  );

  const inputClasses = computed(() => {
    let classes = 'peer min-h-6 focus:outline-none focus-visible:outline-none bg-input-bg ';
    if (props.label && !isFocus.value) {
      classes += 'placeholder-transparent';
    }
    if (props.disabled) {
      classes += ' text-disabled';
    }
    return classes;
  });
  const iconClasses = computed(() => {
    return cn(
      'text-lg text-input-text outline-none hover:text-input-active-text focus:text-active-input-text/80 flex items-center justify-center w-8 h-8',
      props.error && 'text-input-error-text hover:text-input-error-text focus:text-input-error-text/80'
    );
  });

  const wrapClasses = computed(() =>
    cn(
      'flex gap-1 items-center md:px-3.5 px-2 relative border bg-input-bg rounded-lg border-input-border my-1.5 data-focus:border-input-active-border data-error:border-input-error-border data-disabled:border-disabled data-disabled:text-disabled',
      props.wrapClass
    )
  );

  const inputWrapClasses = computed(() =>
    cn('p-2 flex flex-1 ', props.floatingTop ? 'md:px-4' : 'md:p-4', props.inputWrapClass)
  );

  const showError = computed(() => !props.hideError && !!props.error);

  const focus = () => {
    isFocus.value = true;
  };

  const blur = () => {
    isFocus.value = false;
    emit('blur');
  };

  const onFocus = () => {
    if (inputRef.value !== document.activeElement) {
      inputRef.value?.focus();
    }

    if (!isFocus.value) focus();
    emit('focus');
  };

  const onControlMousedown = (e: MouseEvent) => {
    emit('mousedown:control', e);

    if (props.disabledControlledMouseDown || e.target === inputRef.value) {
      return;
    }

    onFocus();
    e.preventDefault();
  };

  const setRef = (el: any) => {
    inputRef.value = el;
  };

  onMounted(() => {
    if (props.autofocus) {
      onFocus();
    }
  });

  defineExpose({
    onBlur: blur,
    onFocus: focus
  });
</script>
