const DEFAULT_MIN_SCROLL_LOAD = 500;
const MIN_HEIGHT_SCROLL_TO_BOTTOM = 150;
const OFFSET_SCROLL_TOP = 100;

type MessageAttachments = {
  [key: string]: MessageBufferAttachment[];
};

const createAttachment = (file: File): MessageBufferAttachment => {
  const previewType = getFileTypeByName(file.name);
  return {
    uid: useUniqueId('attachment'),
    previewUrl: previewType === 'img' ? window.URL.createObjectURL(file) : '',
    previewType,
    file,
    progress: 1
  };
};

const useChatStore = defineStore('chat', () => {
  const apiRoutes = useApiRoutes();
  const chatAttachmentsStore = useChatAttachmentsStore();
  const roomStore = useRoomStore();
  const myRoomStore = useMyRoomStore();
  const toast = useToast();
  const handleRequest = useHandleRequest();
  const { formatGroupDate } = useFormatDate();
  const { open: openConfirmModal } = useConfirmModal({});
  const { open: openAttachModal } = useModalAttachFileChat({});
  const autoScroll = ref(false);
  const minScrollSizeLoad = ref(DEFAULT_MIN_SCROLL_LOAD);
  const messages = ref<Message[]>([]);
  const scrollerRef = ref<HTMLElement>();
  const coreFormRef = ref();
  const selectMessages = ref<number[]>([]);
  const editMessageId = ref(0);
  const replayMessageId = ref(0);
  const activeToMessage = ref(0);
  const activeMenuMessageId = ref(0);
  const showScrollToBottom = ref(false);
  const attachments = ref<MessageBufferAttachment[]>([]);
  const messagesAttachments = ref<MessageAttachments>({});
  const { user } = useAuthUser();
  const messagesMeta = reactive<AdvancedPaginationMeta>({
    prev: {},
    next: {},
    search: {
      prev: {},
      next: {}
    }
  });
  const loading = reactive({
    prev: false,
    next: false,
    global: false
  });
  const activeSelect = computed(() => !!selectMessages.value.length);
  const hasPrev = computed(() => !!messagesMeta.prev.next_cursor);
  const hasNext = computed(() => !!messagesMeta.next.next_cursor);
  const groupByMessages = computed(() => {
    return useGroupBy(
      useFilter(messages.value, msg => !checkMessageIsEmpty(msg)),
      message => formatGroupDate(message.created_at)
    );
  });

  const fetchMessages = async (params: FetchMessagesParams = {}) => {
    if (roomStore.disableFetchMessages) {
      return false;
    }
    const res = await apiRoutes.rooms.getMessages(roomStore.roomId, params);
    if (res._data) {
      if (roomStore.isVisibleHistory) {
        const idsNotRead: number[] = [];
        useEach(res._data.data, message => {
          if (!message.read_at && user.value?.id !== message.author_id) {
            idsNotRead.push(message.id);
          }
        });
        if (idsNotRead.length) {
          readMessages(idsNotRead);
        }
      }

      return res._data;
    }
    return false;
  };

  const createMessage = async (messageBody: CreateMessage) => {
    const res = await apiRoutes.rooms.createMessage(roomStore.roomId, messageBody);
    if (attachments.value.length) {
      onUploadAttachments(res._data.data.id);
    }

    if (!messagesMeta.next.next_cursor) {
      messages.value.push(res._data.data);
      scrollToBottom();
    }

    return true;
  };

  const onSelectEditMessage = (messageId: number) => {
    editMessageId.value = messageId;
  };

  const onSelectReplyMessage = (messageId: number) => {
    replayMessageId.value = messageId;
  };

  const readMessages = async (messageIds: number[] = []) => {
    await apiRoutes.rooms.bulkMessageRead(roomStore.roomId, messageIds);

    let readCount = 0;
    messages.value = messages.value.map(message => {
      if (!message.read_at) {
        message.read_at = new Date().toISOString();
        readCount += 1;
      }
      return message;
    });
    if (readCount) {
      const myRoom = myRoomStore.findRoom(roomStore.roomId);
      if (myRoom) {
        myRoomStore.updateRoom({
          id: myRoom.id,
          unread_messages_count: Math.max(myRoom.unread_messages_count - readCount, 0)
        });
      }
    }
  };

  const setMessages = (newMessages: Message[]) => {
    messages.value = newMessages;
  };

  const updateMessageData = (message: Partial<Message>) => {
    messages.value = updateItemArray<Message, 'id'>(messages, message);
  };

  const updateMessage = async (messageId: number, newMessages: CreateMessage) => {
    const res = await apiRoutes.rooms.updateMessage(roomStore.roomId, messageId, newMessages);
    if (res._data?.data) {
      updateMessageData(res._data.data);
    }

    return true;
  };

  const createOrUpdateMessage = (message: Message) => {
    messages.value = upsert<Message, 'id'>(messages.value, message);
  };

  const deleteMessage = (messageId: number) => {
    messages.value = useFilter(messages.value, message => {
      if (message.id === messageId) {
        chatAttachmentsStore.removeAttachments(useMap(message.attachments, 'id'));
        return false;
      }
      return true;
    });
  };

  const onDeleteMessageReq = (messageId: number) => {
    handleRequest(apiRoutes.rooms.deleteMessage(roomStore.roomId, messageId), {
      showServerError: true
    });
    deleteMessage(messageId);
  };

  const onDeleteMessage = (messageId: number) => {
    openConfirmModal({
      title: 'Are you sure you want to delete this message for everyone?',
      confirmText: 'Yes',
      onSuccess() {
        onDeleteMessageReq(messageId);
        if (editMessageId.value === messageId) {
          onSelectEditMessage(0);
          coreFormRef.value?.setValues({ message: '' });
        }
      }
    });
  };

  const onDeleteMessages = () => {
    if (selectMessages.value.length) {
      openConfirmModal({
        title: 'Are you sure you want to delete this messages for everyone?',
        confirmText: 'Yes',
        onSuccess() {
          handleRequest(apiRoutes.rooms.bulkMessageDelete(roomStore.roomId, selectMessages.value), {
            showServerError: true
          });
          messages.value = messages.value.filter(({ id }) => !selectMessages.value.includes(id));
          selectMessages.value = [];
        }
      });
    }
  };

  const onSelectMessages = (messageId: number) => {
    selectMessages.value = useXor(selectMessages.value, [messageId]);
  };

  const onLoadMessage = async (direct: 'prev' | 'next' = 'prev', onBeforeAddItem?: () => void) => {
    const cursor = messagesMeta[direct].next_cursor;
    if (!cursor || loading[direct]) {
      return false;
    }
    loading[direct] = true;
    const res = await fetchMessages({
      ...messagesMeta.search[direct],
      cursor
    });
    if (res) {
      messagesMeta[direct] = res.meta;
      const message = res.data;
      onBeforeAddItem?.();
      if (direct === 'prev') {
        messages.value.unshift(...message.reverse());
      } else {
        messages.value.push(...message);
      }
    }

    loading[direct] = false;
    return true;
  };

  const setMeta = (meta: PaginationMetaCursor, direct: 'prev' | 'next' = 'prev') => {
    messagesMeta[direct] = meta;
  };

  const scrollToBottom = (behavior: ScrollBehavior = 'smooth') => {
    autoScroll.value = true;
    requestAnimationFrame(() => {
      if (scrollerRef.value) {
        scrollerRef.value.scrollTo({
          top: scrollerRef.value.scrollHeight,
          behavior
        });
      }

      autoScroll.value = false;
    });
  };

  const checkShowScrollToBottom = () => {
    if (!scrollerRef.value) {
      return;
    }

    if (messagesMeta.next?.next_cursor) {
      showScrollToBottom.value = true;
      return;
    }

    const scrollHeight = scrollerRef.value.scrollHeight;
    const scrollTop = scrollerRef.value.scrollTop;
    const clientHeight = scrollerRef.value.clientHeight;
    showScrollToBottom.value = scrollHeight - (scrollTop + clientHeight) >= MIN_HEIGHT_SCROLL_TO_BOTTOM;
  };

  const onScrollToBottom = async () => {
    if (messagesMeta.next?.next_cursor) {
      loading.global = true;
      resetMessageDataAndResetMeta();
      await initMessages();
      loading.global = false;
      return;
    }
    scrollToBottom();
  };

  const initMessages = async () => {
    const res = await fetchMessages();
    if (res) {
      setMessages(res.data.reverse());
      setMeta(res.meta);
      scrollToBottom('instant');
    }
  };

  const setActiveToMessageId = (messageId: number) => {
    activeToMessage.value = messageId;
  };

  const scrollMessageToId = (messageId: number) => {
    setActiveToMessageId(messageId);
    const messageIdx = useFindIndex(messages.value, { id: messageId });
    if (messageIdx >= 0) {
      autoScroll.value = true;
      setTimeout(() => {
        const offsetTop = findDoomMessage(messageId)?.offsetTop || 0;
        scrollerRef.value?.scrollTo({
          top: offsetTop - OFFSET_SCROLL_TOP,
          behavior: 'smooth'
        });
        autoScroll.value = false;
      });
      return true;
    }
    return false;
  };

  const goToMessage = async (messageId: number) => {
    autoScroll.value = true;
    if (!scrollMessageToId(messageId)) {
      if (!roomStore.disableFetchMessages) {
        reset();
        loading.global = true;
        const nextParams = {
          to_id: messageId
        };
        const nextRes = await fetchMessages(nextParams);
        if (nextRes) {
          messagesMeta.next = nextRes.meta;
          messages.value = nextRes.data;
          messagesMeta.search.next = nextParams;
          const lastMessageId = messages.value?.[0]?.id;
          if (messages.value.length) {
            const prevParams = {
              before_id: lastMessageId
            };
            const prevRes = await fetchMessages(prevParams);
            if (prevRes) {
              messagesMeta.prev = prevRes.meta;
              messages.value.unshift(...prevRes.data.reverse());
              messagesMeta.search.prev = prevParams;
            }
          }
          loading.global = false;

          setTimeout(() => {
            if (nextRes) {
              if (lastMessageId !== messageId) {
                toast.error(MessagesError.messageNotFound);
              }
              scrollMessageToId(lastMessageId);

              autoScroll.value = false;
            }
          });
          return;
        }
        loading.global = false;
        autoScroll.value = false;
        return true;
      }
      const lastMessageId = roomStore.room?.unread_message_id;
      if (lastMessageId && messageId !== lastMessageId) {
        await goToMessage(lastMessageId);
      } else {
        await initMessages();
      }
    }
    autoScroll.value = false;
  };

  const onScrollMessages = useDebounceFn(async () => {
    if (!scrollerRef.value || autoScroll.value) {
      return;
    }
    const el = scrollerRef.value;
    let scrollTopBefore = el.scrollTop;
    let scrollHeightBefore = el.scrollHeight;
    const clientHeight = el.clientHeight;
    checkShowScrollToBottom();
    if (scrollTopBefore <= minScrollSizeLoad.value) {
      const isLoad = await onLoadMessage('prev', () => {
        scrollTopBefore = el.scrollTop;
        scrollHeightBefore = el.scrollHeight;
      });
      if (isLoad) {
        nextTick(() => {
          const scrollHeightAfter = el.scrollHeight;
          el.scrollTop = scrollHeightAfter - scrollHeightBefore + scrollTopBefore;
        });
      }
      return;
    }

    if (scrollTopBefore + clientHeight >= scrollHeightBefore - minScrollSizeLoad.value) {
      await onLoadMessage('next');
    }
  }, 50);

  const setScrollerRef = (el?: any) => {
    scrollerRef.value = el;
  };

  const setCoreFormRef = (el?: any) => {
    coreFormRef.value = el;
  };

  const setMinScrollSizeLoad = (size: number = DEFAULT_MIN_SCROLL_LOAD) => {
    minScrollSizeLoad.value = size;
  };

  const removeSelectAttachment = (attachmentUid: string) => {
    attachments.value = removeItemArray(attachments.value, attachmentUid, 'uid');
  };

  const findMessage = (messageId: number) => useFind(messages.value, { id: messageId });
  const checkMessageIsEmpty = (message?: Message) => {
    return !message || !(message.message?.length || message.tenor_url || message.attachments?.length);
  };
  const checkMessageIsEmptyWithId = (messageId: number) => {
    return checkMessageIsEmpty(findMessage(messageId));
  };

  const removeMessageProcessAttachment = (messageId: number, attachmentUid: string) => {
    if (messagesAttachments.value[messageId]) {
      messagesAttachments.value[messageId] = useFilter(messagesAttachments.value[messageId], attachment => {
        if (attachment.uid === attachmentUid) {
          attachment.controller?.abort();
          return false;
        }
        return true;
      });
    }
  };

  const updateMessageAttachment = (attachment: MessageAttachment) => {
    const messageIdx = useFindIndex(messages.value, {
      id: attachment.message_id
    });
    messages.value[messageIdx].attachments = upsert(messages.value[messageIdx].attachments, attachment);
    chatAttachmentsStore.onUpdateMessageAttachments(messages.value[messageIdx]);
  };

  const removeMessageAttachment = (messageId: number, attachmentId: number) => {
    const messageIdx = useFindIndex(messages.value, { id: messageId });
    messages.value[messageIdx].attachments = removeItemArray(messages.value[messageIdx].attachments, attachmentId);
    chatAttachmentsStore.removeAttachments([attachmentId]);
  };

  const uploadAttachment = (messageId: number, attachment: MessageBufferAttachment) => {
    if (useIncludes([MessageAttachmentProgress.Pending, MessageAttachmentProgress.Error], attachment.progress)) {
      attachment.progress = MessageAttachmentProgress.Upload;
      attachment.controller = new AbortController();
      handleRequest(
        apiRoutes.rooms.createMessageAttachment(roomStore.roomId, messageId, {
          body: objToFormData({ file: attachment.file }),
          timeout: 0,
          signal: attachment.controller.signal
        })
      ).then(({ isError, data }) => {
        attachment.progress = isError ? MessageAttachmentProgress.Error : MessageAttachmentProgress.Success;
        if (isError) {
          return;
        }
        removeMessageProcessAttachment(messageId, attachment.uid);
        updateMessageAttachment(data);
      });
    }
    return attachment;
  };

  const onUploadAttachments = (messageId: number) => {
    messagesAttachments.value[messageId] = useMap(attachments.value, attachment => {
      return uploadAttachment(messageId, attachment);
    });
    resetAttachments();
  };

  const retryUploadAttachment = (messageId: number, attachmentUid: string) => {
    if (messagesAttachments.value[messageId]) {
      messagesAttachments.value[messageId] = useMap(messagesAttachments.value[messageId], attachment => {
        if (attachment.uid === attachmentUid) {
          return uploadAttachment(messageId, attachment);
        }
        return attachment;
      });
    }
  };

  const onSelectAttachment = (files: File[]) => {
    if (attachments.value.length) {
      useEach(files, file => {
        attachments.value.push(createAttachment(file));
      });
      return;
    }

    attachments.value = useMap(files, createAttachment);
  };

  const onRemoveAttachment = async (messageId: number, attachmentId: number) => {
    return new Promise<boolean>(resolve => {
      openConfirmModal({
        title: 'Are you sure you want to delete this attachment for everyone?',
        confirmText: 'Yes',
        onSuccess() {
          removeMessageAttachment(messageId, attachmentId);
          resolve(true);
          if (checkMessageIsEmptyWithId(messageId)) {
            onDeleteMessageReq(messageId);
            return;
          }
          handleRequest(apiRoutes.rooms.deleteMessageAttachment(roomStore.roomId, messageId, attachmentId), {
            showServerError: true
          });
        }
      });
    });
  };

  const onSelectAttachmentWithModal = (
    files: File[],
    props: { initMessage?: string; messageId?: number; isModal?: boolean } = {}
  ) => {
    if (files.length) {
      onSelectAttachment(files);
      if (!props.isModal) {
        openAttachModal({
          initMessage: props.initMessage,
          messageId: props.messageId,
          'onUpdate:modelValue': () => resetAttachments(),
          onSuccess: () => {
            if (props.messageId && attachments.value.length) {
              onUploadAttachments(props.messageId);
            }
          }
        });
      }
    }
  };

  const setActiveMenuMessage = (messageId: number) => {
    activeMenuMessageId.value = messageId;
  };

  const resetFormMessageData = () => {
    onSelectEditMessage(0);
    onSelectReplyMessage(0);
  };

  const resetAttachments = () => {
    attachments.value = [];
  };

  const resetSelect = () => {
    selectMessages.value = [];
  };

  const addNewMessageWithSocket = (message: Message) => {
    if (messagesMeta.next.next_cursor) {
      return;
    }
    messages.value = upsert(messages.value, message);
  };

  const resetMessageDataAndResetMeta = () => {
    messagesMeta.prev = {};
    messagesMeta.next = {};
    messagesMeta.search.next = {};
    messagesMeta.search.prev = {};
    messages.value = [];
    messagesAttachments.value = {};
    loading.prev = false;
    loading.next = false;
    loading.global = false;
    showScrollToBottom.value = false;
  };

  const reset = () => {
    chatAttachmentsStore.reset();
    resetAttachments();
    resetSelect();
    resetMessageDataAndResetMeta();
    resetFormMessageData();
    setMinScrollSizeLoad();
    setActiveToMessageId(0);
    setActiveMenuMessage(0);
    setScrollerRef(undefined);
    setCoreFormRef(undefined);
  };

  return {
    hasPrev,
    hasNext,
    messages,
    messagesMeta,
    loading,
    selectMessages,
    activeSelect,
    editMessageId,
    replayMessageId,
    activeMenuMessageId,
    activeToMessage,
    attachments,
    messagesAttachments,
    groupByMessages,
    showScrollToBottom,
    coreFormRef,

    setActiveMenuMessage,
    onScrollToBottom,
    checkShowScrollToBottom,
    setActiveToMessageId,
    resetSelect,
    onSelectMessages,
    onSelectReplyMessage,
    onSelectEditMessage,
    onDeleteMessage,
    onDeleteMessages,
    readMessages,
    initMessages,
    updateMessageData,
    createOrUpdateMessage,
    resetFormMessageData,
    setMessages,
    setMeta,
    setScrollerRef,
    setCoreFormRef,
    setMinScrollSizeLoad,
    fetchMessages,
    updateMessage,
    createMessage,
    onLoadMessage,
    reset,
    goToMessage,
    scrollMessageToId,
    scrollToBottom,
    onScrollMessages,
    deleteMessage,
    addNewMessageWithSocket,

    onSelectAttachmentWithModal,
    resetAttachments,
    removeSelectAttachment,
    removeMessageProcessAttachment,
    retryUploadAttachment,
    onUploadAttachments,
    onSelectAttachment,
    removeMessageAttachment,
    onRemoveAttachment,
    checkMessageIsEmptyWithId,
    onDeleteMessageReq,
    findMessage
  };
});

export default useChatStore;
