<template>
  <ui-card class="flex flex-col items-center justify-center py-20 lg:py-40 text-center">
    <slot />
    <ui-typography
      variant="h3"
      class="max-w-64 md:max-w-96 lg:max-w-xl"
      text="It seems there are no rooms with such interests.
Try changing your interests."
    />
    <ui-typography
      variant="h3"
      text="Or"
      class="mb-2.5"
    />
    <ui-button
      text="Create Your Own Room"
      variant="link"
      underline="always"
      :to="{ name: 'dashboard-rooms-create' }"
    />
  </ui-card>
</template>
