<template>
  <ui-button-async v-bind="{ ...attrs, ...$attrs } as any" />
</template>

<script setup lang="ts">
  type Props = {
    userStatus: CustomUserStatusForRoom;
    room: RoomPreview;
  };

  const props = defineProps<Props>();
  const emit = defineEmits(['success']);
  const roomStore = useRoomStore();

  const attrs = computed(() => {
    if (props.userStatus.guest) {
      return {
        text: props.room.type === RoomType.Room ? 'Join' : 'Approve',
        iconLeft: 'favorite',
        request: () => roomStore.onJoinRoom(props.room),
        onSuccess: () => {
          emit('success', {
            id: props.room.id,
            user_status: {
              status: RoomUserStatus.Waiting
            }
          });
        }
      };
    }

    if (props.userStatus.waiting) {
      return {
        text: 'Cancel Request',
        iconLeft: 'block',
        variant: 'secondary',
        request: () => roomStore.onLeaveRoom(props.room.id),
        onSuccess: () => {
          emit('success', {
            id: props.room.id,
            user_status: null
          });
        }
      };
    }
    return {};
  });
</script>
