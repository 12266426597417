import routerOptions0 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_occ24bpjx6ysxvozvhqbyq77ja/node_modules/nuxt/dist/pages/runtime/router.options.js";
const configRouterOptions = {
  strict: true,
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = false
export default {
...configRouterOptions,
...routerOptions0,
}