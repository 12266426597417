const EMBED_DOMAINS = {
  youtube: ['youtu.be', 'youtube.com'],
  vimeo: 'vimeo.com'
};

type TDomain = keyof typeof EMBED_DOMAINS;

type ExtractDomain = {
  hostname: string;
  domain: string;
};
const extractHostname = (url: string) => {
  let hostname;
  // find & remove protocol (http, ftp, etc.) and get hostname
  if (url.includes('//')) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }
  // find & remove port number
  hostname = hostname.split(':')[0];
  // find & remove "?"
  hostname = hostname.split('?')[0];
  return hostname;
};
const extractRootDomain = (url: any): ExtractDomain => {
  if (!url || typeof url !== 'string') {
    return url;
  }
  const hostname = extractHostname(url);
  let domain = hostname;
  const splitArr = domain.split('.');
  const arrLen = splitArr.length;

  // extracting the root domain here
  // if there is a subdomain
  if (arrLen > 2) {
    domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
    // check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (splitArr[arrLen - 2].length === 2 && splitArr[arrLen - 1].length === 2) {
      // this is using a ccTLD
      domain = splitArr[arrLen - 3] + '.' + domain;
    }
  }
  return {
    hostname,
    domain
  };
};

const getProviderName = (options: ExtractDomain | string) => {
  if (typeof options === 'string') {
    return options;
  }
  const { domain, hostname } = options;

  const key = useFindKey(EMBED_DOMAINS, val => {
    if (isArray(val)) {
      return !!(val.includes(domain) || val.includes(hostname));
    }
    return domain === val || hostname === val;
  });
  return key || domain || hostname;
};

const checkIsValid = (provider: string, validProviders: string | string[] = Object.keys(EMBED_DOMAINS)): boolean => {
  if (isArray(validProviders)) {
    return validProviders.some(val => checkIsValid(provider, val));
  }
  return provider === validProviders;
};

const validateDomains = (link: string, onlyProvider: TDomain | TDomain[]) => {
  const provider = getProviderName(extractRootDomain(link));

  return {
    isValid: checkIsValid(provider, onlyProvider),
    provider
  };
};

export { getProviderName, validateDomains };
