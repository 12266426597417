<template>
  <div
    class="fixed lg:static pt-14 lg:pt-0 inset-0 z-50 backdrop-blur-sm lg:bg-transparent bg-white/10 overflow-y-auto"
  >
    <ui-card class="m-2.5 lg:m-0">
      <form @submit="onSubmit">
        <div class="relative m-2.5 lg:m-0 rounded-lg">
          <div class="lg:hidden text-right mb-2.5">
            <ui-button
              icon-left="close"
              variant="secondary"
              size="xs-icon"
              rounded="sm"
              @click="onClose"
            />
          </div>
          <common-filters-public-interests />
          <div class="flex gap-2.5 lg:gap-7 mt-2.5 lg:mt-0 max-w-xl w-full mx-auto">
            <ui-button
              text="Clean"
              variant="secondary-outline"
              class="w-full"
              @click="onReset"
            />
            <ui-button
              text="Apply"
              class="w-full"
              type="submit"
            />
          </div>
        </div>
      </form>
    </ui-card>
  </div>
</template>

<script setup lang="ts">
  const emit = defineEmits(['close', 'update']);
  const parentFormValues = useFormValues();

  const initialValues = computed(() => {
    return {
      interest_ids: parentFormValues.value.interest_ids
    };
  });

  const { values, handleSubmit } = useForm({
    initialValues
  });

  const onClose = () => {
    emit('close');
  };

  const onReset = () => {
    emit('update', { interest_ids: [] });
    onClose();
  };

  const onSubmit = handleSubmit(() => {
    emit('update', {
      ...values,
      cursor: ''
    });
    onClose();
  });
</script>
