<template>
  <nuxt-link-locale
    :to="{ name: 'index' }"
    class="inline-flex md:p-2 p-1 items-center bg-card rounded-full shrink-0"
  >
    <img
      class="md:w-16 md:h-16 w-12 h-12"
      width="64"
      height="64"
      src="/assets/images/app-logo.svg"
      fetchpriority="low"
      alt="logo"
    />
  </nuxt-link-locale>
</template>
