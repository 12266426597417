
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as forgot_45password2WrLTQbranMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/(auth)/forgot-password.vue?macro=true";
import { default as loginLf9cmTrLI2Meta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/(auth)/login.vue?macro=true";
import { default as registrationA4iKFVXFKQMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/(auth)/registration.vue?macro=true";
import { default as reset_45password2hrM75hy9NMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/(auth)/reset-password.vue?macro=true";
import { default as supportA5iNyZPw1NMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/(auth)/support.vue?macro=true";
import { default as privacy_45policyu4m9j9lBwpMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/(policy)/privacy-policy.vue?macro=true";
import { default as refund_45policy60ZCLlqWpjMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/(policy)/refund-policy.vue?macro=true";
import { default as termsk7QkwgSOmNMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/(policy)/terms.vue?macro=true";
import { default as _91provider_93vSUJOaRoSqMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/auth/[provider].vue?macro=true";
import { default as unverified4QADYNzRMqMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/auth/unverified.vue?macro=true";
import { default as verificationHTV4AiWEDyMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/auth/verification.vue?macro=true";
import { default as coinsXhF1f5wr8nMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/coins.vue?macro=true";
import { default as my_45roomspUzyiqro7pMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/my-rooms.vue?macro=true";
import { default as notificationsLHnRcwGUYRMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/notifications.vue?macro=true";
import { default as planOIvwmqXHGMMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/plan.vue?macro=true";
import { default as profileqbpJGJAHtBMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/profile.vue?macro=true";
import { default as createu4QXFkRMd8Meta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/admins/create.vue?macro=true";
import { default as indexVBpJ0dhaABMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/admins/index.vue?macro=true";
import { default as adminseEgrtqcIvKMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/admins.vue?macro=true";
import { default as chatvJOa61A8puMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/chat.vue?macro=true";
import { default as editrk4IYsyFU8Meta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/edit.vue?macro=true";
import { default as indexcAPEZWHqLVMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/index.vue?macro=true";
import { default as settingsANDLKXfSqdMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/settings.vue?macro=true";
import { default as _91roomId_938ndOxeYoD0Meta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId].vue?macro=true";
import { default as creates0jBvZbuE9Meta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/create.vue?macro=true";
import { default as indexq2njqYw1BrMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/index.vue?macro=true";
import { default as general9NzBfJL6ocMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/general.vue?macro=true";
import { default as passwordr2ofh2UMvoMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/password.vue?macro=true";
import { default as privacy_45policyjSKVPtZDjlMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/privacy-policy.vue?macro=true";
import { default as refund_45policyoezZjs3rnvMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/refund-policy.vue?macro=true";
import { default as social_45profilescSJ8ZXgt07Meta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/social-profiles.vue?macro=true";
import { default as supportsoNwM1gymUMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/support.vue?macro=true";
import { default as terms4OrBsL18y0Meta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/terms.vue?macro=true";
import { default as settingsuIDrsjF5ceMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings.vue?macro=true";
import { default as setup_45profilefhLYTKPNI1Meta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/setup-profile.vue?macro=true";
import { default as indexynZGdFwRSIMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/subscriptions/index.vue?macro=true";
import { default as dashboard0mhrbND48jMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard.vue?macro=true";
import { default as indexjPZKzqX8tZMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/index.vue?macro=true";
import { default as offline1u9GVFEPbgMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/offline.vue?macro=true";
import { default as _91username_93ateA8wEOjqMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/profile/[username].vue?macro=true";
import { default as uiTvmYs8qqIeMeta } from "/home/runner/work/dating-frontend/dating-frontend/src/pages/ui.vue?macro=true";
import { default as component_45stubXln5BXkPLtMeta } from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_occ24bpjx6ysxvozvhqbyq77ja/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubXln5BXkPLt } from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_occ24bpjx6ysxvozvhqbyq77ja/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "forgot-password___en",
    path: "/forgot-password",
    meta: forgot_45password2WrLTQbranMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/(auth)/forgot-password.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginLf9cmTrLI2Meta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/(auth)/login.vue")
  },
  {
    name: "registration___en",
    path: "/registration",
    meta: registrationA4iKFVXFKQMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/(auth)/registration.vue")
  },
  {
    name: "reset-password___en",
    path: "/reset-password",
    meta: reset_45password2hrM75hy9NMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/(auth)/reset-password.vue")
  },
  {
    name: "support___en",
    path: "/support",
    meta: supportA5iNyZPw1NMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/(auth)/support.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    meta: privacy_45policyu4m9j9lBwpMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/(policy)/privacy-policy.vue")
  },
  {
    name: "refund-policy___en",
    path: "/refund-policy",
    meta: refund_45policy60ZCLlqWpjMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/(policy)/refund-policy.vue")
  },
  {
    name: "terms___en",
    path: "/terms",
    meta: termsk7QkwgSOmNMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/(policy)/terms.vue")
  },
  {
    name: "auth-provider___en",
    path: "/auth/:provider()",
    meta: _91provider_93vSUJOaRoSqMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/auth/[provider].vue")
  },
  {
    name: "auth-unverified___en",
    path: "/auth/unverified",
    meta: unverified4QADYNzRMqMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/auth/unverified.vue")
  },
  {
    name: "auth-verification___en",
    path: "/auth/verification",
    meta: verificationHTV4AiWEDyMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/auth/verification.vue")
  },
  {
    name: "dashboard___en",
    path: "/dashboard",
    meta: dashboard0mhrbND48jMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-coins___en",
    path: "coins",
    meta: coinsXhF1f5wr8nMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/coins.vue")
  },
  {
    name: "dashboard-my-rooms___en",
    path: "my-rooms",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/my-rooms.vue")
  },
  {
    name: "dashboard-notifications___en",
    path: "notifications",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/notifications.vue")
  },
  {
    name: "dashboard-plan___en",
    path: "plan",
    meta: planOIvwmqXHGMMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/plan.vue")
  },
  {
    name: "dashboard-profile___en",
    path: "profile",
    meta: profileqbpJGJAHtBMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/profile.vue")
  },
  {
    name: _91roomId_938ndOxeYoD0Meta?.name,
    path: "rooms/:roomId()",
    meta: _91roomId_938ndOxeYoD0Meta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId].vue"),
    children: [
  {
    name: adminseEgrtqcIvKMeta?.name,
    path: "admins",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/admins.vue"),
    children: [
  {
    name: "dashboard-rooms-roomId-admins-create___en",
    path: "create",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/admins/create.vue")
  },
  {
    name: "dashboard-rooms-roomId-admins___en",
    path: "",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/admins/index.vue")
  }
]
  },
  {
    name: "dashboard-rooms-roomId-chat___en",
    path: "chat",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/chat.vue")
  },
  {
    name: "dashboard-rooms-roomId-edit___en",
    path: "edit",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/edit.vue")
  },
  {
    name: "dashboard-rooms-roomId___en",
    path: "",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/index.vue")
  },
  {
    name: "dashboard-rooms-roomId-settings___en",
    path: "settings",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/[roomId]/settings.vue")
  }
]
  },
  {
    name: "dashboard-rooms-create___en",
    path: "rooms/create",
    meta: creates0jBvZbuE9Meta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/create.vue")
  },
  {
    name: "dashboard-rooms___en",
    path: "rooms",
    meta: indexq2njqYw1BrMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/rooms/index.vue")
  },
  {
    name: "dashboard-settings___en",
    path: "settings",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings.vue"),
    children: [
  {
    name: "dashboard-settings-general___en",
    path: "general",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/general.vue")
  },
  {
    name: "dashboard-settings-password___en",
    path: "password",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/password.vue")
  },
  {
    name: "dashboard-settings-privacy-policy___en",
    path: "privacy-policy",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/privacy-policy.vue")
  },
  {
    name: "dashboard-settings-refund-policy___en",
    path: "refund-policy",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/refund-policy.vue")
  },
  {
    name: "dashboard-settings-social-profiles___en",
    path: "social-profiles",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/social-profiles.vue")
  },
  {
    name: "dashboard-settings-support___en",
    path: "support",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/support.vue")
  },
  {
    name: "dashboard-settings-terms___en",
    path: "terms",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/settings/terms.vue")
  }
]
  },
  {
    name: "dashboard-setup-profile___en",
    path: "setup-profile",
    meta: setup_45profilefhLYTKPNI1Meta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/setup-profile.vue")
  },
  {
    name: "dashboard-subscriptions___en",
    path: "subscriptions",
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/dashboard/subscriptions/index.vue")
  }
]
  },
  {
    name: "index___en",
    path: "/",
    meta: indexjPZKzqX8tZMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/index.vue")
  },
  {
    name: "offline___en",
    path: "/offline",
    meta: offline1u9GVFEPbgMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/offline.vue")
  },
  {
    name: "profile-username___en",
    path: "/profile/:username()",
    meta: _91username_93ateA8wEOjqMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/profile/[username].vue")
  },
  {
    name: "ui___en",
    path: "/ui",
    meta: uiTvmYs8qqIeMeta || {},
    component: () => import("/home/runner/work/dating-frontend/dating-frontend/src/pages/ui.vue")
  },
  {
    name: component_45stubXln5BXkPLtMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubXln5BXkPLt
  }
]