<template>
  <main class="flex lg:flex-row flex-col lg:min-h-screen lg:overflow-y-auto bg-card">
    <ui-parts-random-background
      :class="{ 'lg:flex hidden': $route.meta.hideSidebarMobile }"
      class="lg:w-1/2"
    />
    <div class="flex flex-col lg:h-dvh lg:overflow-y-auto lg:w-1/2">
      <slot />
    </div>
  </main>
</template>

<script setup lang="ts">
  onMounted(() => {
    document.querySelector('html')?.classList.add('lg:overflow-y-hidden', 'bg-card');
  });
  onScopeDispose(() => {
    document.querySelector('html')?.classList.remove('lg:overflow-y-hidden', 'bg-card');
  });
</script>
