export const appHead = {"link":[{"rel":"shortcut icon","type":"image/x-icon","href":"/icons/favicon.ico"},{"rel":"icon","sizes":"32x32","href":"/icons/logo-32x32.png"},{"rel":"icon","sizes":"48x48","href":"/icons/logo-48x48.png"},{"rel":"icon","sizes":"96x96","href":"/icons/logo-96x96.png"},{"rel":"icon","sizes":"144x144","href":"/icons/logo-144x144.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/icons/logo-180x180.png"},{"name":"msapplication-TileColor","content":"#da532c"},{"name":"theme-color","content":"#ffffff"},{"rel":"mask-icon","href":"/icons/safari-pinned-tab.svg","color":"#000000"},{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","crossorigin":"","href":"https://fonts.gstatic.com"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&family=Material+Icons&display=swap"}],"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"},{"property":"og:type","content":"website"}],"style":[],"script":[],"noscript":[],"bodyAttrs":{"id":"app"},"htmlAttrs":{"lang":"en"}}

export const appLayoutTransition = false

export const appPageTransition = {"name":"fade","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false