import revive_payload_client_iL0GEE1TDN from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_occ24bpjx6ysxvozvhqbyq77ja/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CCORYmxEmZ from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_occ24bpjx6ysxvozvhqbyq77ja/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NTX1pK5hUn from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_occ24bpjx6ysxvozvhqbyq77ja/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_x4MZ15K70n from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@3.29.5_vite@6.0.7_@types+node@22.10.5_jiti@2.4._r6m7pqzafmvg6zpslk7dxz5pza/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_uZ8KucBuK6 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_occ24bpjx6ysxvozvhqbyq77ja/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_4Qr9Wr3PYu from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_occ24bpjx6ysxvozvhqbyq77ja/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_lCbzs5ouml from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_occ24bpjx6ysxvozvhqbyq77ja/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GfSkuorrjt from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_occ24bpjx6ysxvozvhqbyq77ja/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VspuhFdpnQ from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@3.29.5_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/dating-frontend/dating-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_gu3280HTQa from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_occ24bpjx6ysxvozvhqbyq77ja/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_DYXnchVUPr from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@3.29.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_client_NbISvMZEQH from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_magicast@0.3.5_rollup@3.29.5_vue@3.5.13_typescript@5.7.2_/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_fvN6UhkcOb from "/home/runner/work/dating-frontend/dating-frontend/src/modules/nuxt-auth-sanctum/runtime/plugin.ts";
import http_plugin_nuxt_0nMtAvzz9d from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxt-alt+http@1.7.10_magicast@0.3.5_nuxi@3.17.2_rollup@3.29.5_sass@1.83.0_typescript@5.7.2_v_rvlyn4assa3t43c3cfkky3wa7a/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import switch_locale_path_ssr_UvqIX9uVaE from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@3.29.5_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_026XQN1zf5 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@3.29.5_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_856k7fb6P4 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@3.29.5_typescript@5_mcehp36v4my7zvvhjz3fh646im/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_client_fwdHnKzYU2 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-viewport@2.2.0_magicast@0.3.5_rollup@3.29.5_vue@3.5.13_typescript@5.7.2_/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_YcEBpsQ7yc from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@3.29.5/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import nuxt_plugin_08tePjWAms from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@3.29.5/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_WQk09nonbm from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_magicast@0.3.5_rollup@3.29.5_vite@6.0.7_@types+node@22.10.5_jiti@2.4.2_s_xcgsvar26kosfdiafo6dssfkwe/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_rxJCVTpIiC from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_magicast@0.3.5_rollup@3.29.5_vite@6.0.7_@types+node@22.10.5_jiti@2.4.2_s_xcgsvar26kosfdiafo6dssfkwe/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import titles_tihfcbg4Zv from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@unhead+vue@1.11.14_vue@3.5.13_typescript@5.7.2___h3@1.13.0_magicast@_zeiny2wxqfxnq75fbwrl77tpgq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_2igtP2oXtf from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@unhead+vue@1.11.14_vue@3.5.13_typescript@5.7.2___h3@1.13.0_magicast@_zeiny2wxqfxnq75fbwrl77tpgq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_sDXUyEQIOo from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_magicast@0.3.5_rollup@3.29.5_vite@6.0.7_@types+node@22.10.5_j_y6whqdk3aaux5e5zk2xahzda2q/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_ENG18R27MS from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_magicast@0.3.5_rollup@3.29.5_vite@6.0.7_@types+node@22.10.5_j_y6whqdk3aaux5e5zk2xahzda2q/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import api_routes_Q0W49V3wqt from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/api-routes.ts";
import custom_pwa_register_client_fOvh2D2KfW from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/custom-pwa-register.client.ts";
import dompurify_R8xb7bJPqP from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/dompurify.ts";
import floating_1Jsyq3dLZq from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/floating.ts";
import handle_app_color_mn7Bwa8Xck from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/handle-app-color.ts";
import modal_saNsw8xGBA from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/modal.ts";
import pusher_js_client_2TZYA6fBVP from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/pusher-js.client.ts";
import select_fkZYP3esEZ from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/select.ts";
import toast_notification_ycBNrvS44W from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/toast-notification.ts";
export default [
  revive_payload_client_iL0GEE1TDN,
  unhead_CCORYmxEmZ,
  router_NTX1pK5hUn,
  _0_siteConfig_x4MZ15K70n,
  payload_client_uZ8KucBuK6,
  navigation_repaint_client_4Qr9Wr3PYu,
  check_outdated_build_client_lCbzs5ouml,
  chunk_reload_client_GfSkuorrjt,
  plugin_vue3_VspuhFdpnQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gu3280HTQa,
  plugin_client_DYXnchVUPr,
  plugin_client_NbISvMZEQH,
  plugin_fvN6UhkcOb,
  http_plugin_nuxt_0nMtAvzz9d,
  switch_locale_path_ssr_UvqIX9uVaE,
  i18n_026XQN1zf5,
  plugin_856k7fb6P4,
  plugin_client_fwdHnKzYU2,
  plugin_YcEBpsQ7yc,
  nuxt_plugin_08tePjWAms,
  pwa_icons_WQk09nonbm,
  pwa_client_rxJCVTpIiC,
  titles_tihfcbg4Zv,
  defaults_2igtP2oXtf,
  siteConfig_sDXUyEQIOo,
  inferSeoMetaPlugin_ENG18R27MS,
  api_routes_Q0W49V3wqt,
  custom_pwa_register_client_fOvh2D2KfW,
  dompurify_R8xb7bJPqP,
  floating_1Jsyq3dLZq,
  handle_app_color_mn7Bwa8Xck,
  modal_saNsw8xGBA,
  pusher_js_client_2TZYA6fBVP,
  select_fkZYP3esEZ,
  toast_notification_ycBNrvS44W
]