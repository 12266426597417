<template>
  <div
    :style="backgroundStyle"
    class="flex flex-col justify-center items-center rounded-b-3xl lg:rounded-none min-h-full py-7 px-2.5 lg:px-6 bg-no-repeat bg-cover bg-center default-transition"
  >
    <div class="max-w-2xl w-full mb-auto md:pb-36 pb-28 lg:pb-0 pt-5 lg:pt-0 flex items-center justify-between">
      <ui-logo full />
      <common-headers-set-them class="lg:hidden" />
    </div>
    <div class="max-w-2xl w-full lg:ml-auto mb-auto">
      <transition-scale mode="out-in">
        <ui-typography
          :key="currentIndex + 'content'"
          :text="currentSlide.quote"
          tag="h1"
          variant="h1"
          class="text-white max-w-xl relative lg:pt-0 lg-bt-0 mt-4 lg:max-h-[75vh] lg:mt-0 before:content-['“'] before:absolute before:-top-16 lg:before:-top-24 before:left-0 before:text-primary before:text-8xl"
        />
      </transition-scale>
    </div>
  </div>
</template>

<script setup>
  const TIMOUT_DELAY = 5000;
  const randomSlide = [
    {
      background: '/assets/images/bg-login-1.png',
      quote: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa, nemo.'
    },
    {
      background: '/assets/images/bg-login-2.png',
      quote:
        'Excepteur sint occaecat cupidatat id non proident, sunt in culpa qui officia deserunt mollit anim est laborum.'
    },
    {
      background: '/assets/images/bg-login-3.png',
      quote: 'Accusantium aliquam animi facilis nemo nostrum odit pariatur placeat suscipit.'
    },
    {
      background: '/assets/images/bg-login-4.png',
      quote: 'Ad aliquam cupiditate dignissimos doloremque fugiat minus nesciunt sed soluta!'
    },
    {
      background: '/assets/images/bg-login-5.png',
      quote: 'Assumenda deleniti itaque iure nemo nobis pariatur quibusdam saepe voluptatem?'
    },
    {
      background: '/assets/images/bg-login-6.png',
      quote:
        'Excepteur sint occaecat cupidatat id non proident, sunt in culpa qui officia deserunt mollit anim est laborum.'
    }
  ];

  useHead({
    link: useMap(randomSlide, slide => ({
      rel: 'preload',
      href: slide.background,
      as: 'image'
    }))
  });

  const currentIndex = ref(0);
  const currentSlide = computed(() => randomSlide[currentIndex.value]);

  const changeSlide = () => {
    currentIndex.value = (currentIndex.value + 1) % randomSlide.length;
  };

  useIntervalFn(() => {
    changeSlide();
  }, TIMOUT_DELAY);

  const backgroundStyle = computed(() => ({
    backgroundImage: `url(${currentSlide.value.background})`
  }));
</script>
