<template>
  <ui-card
    padding="sm"
    rounded="md"
    class="flex flex-col gap-2.5 cursor-pointer"
    @click="goToRoom(source)"
  >
    <ui-image
      :src="source.avatar_url"
      :alt="source.name"
      class="w-full h-full rounded object-cover aspect-square"
    />
    <div>
      <ui-typography
        tag="h3"
        variant="h3"
        class="truncate"
      >
        {{ source.name }}
      </ui-typography>

      <common-room-counters :room="source" />
    </div>
    <ui-button
      v-if="isMyRoom"
      class="max-w-md"
      size="lg"
      full
      text="View"
      icon-left="visibility"
    />
    <common-room-parts-room-action-button
      v-else
      class="max-w-md"
      full
      size="lg"
      :room="source"
      :user-status="userStatus"
      @click.stop
      @success="roomsStore.onUpdateRoom"
    />
  </ui-card>
</template>

<script setup lang="ts">
  const props = defineProps<{
    source: RoomPreview;
    showOnline?: boolean;
  }>();

  const userStatus = computed(() => {
    return getUserStatusForRoom(props.source);
  });
  const roomsStore = useRoomsStore();
  const isMyRoom = computed(() => !(userStatus.value.guest || userStatus.value.waiting));

  const goToRoom = useGoToRoom();
</script>
