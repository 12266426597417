import { stringify as qsStringify, parse as qsParse } from 'qs';
import { type FormOptions, useForm } from 'vee-validate';
import type { TypedRoute } from '@typed-router';

type TValues = Record<string, any> & {};

type FiltersQueryOptions = {
  formOptions: FormOptions<TValues>;
  isCleanQuery: boolean;
  withDebounce: boolean;
  onBeforeSetQueryItems?: (val: TValues, currentVal: TValues) => object | undefined;
  onLoadingData: () => any;
  prepareTypedObj: (val: any) => any;
  debounceTime: number;
};

const defaultFiltersQueryOptions: FiltersQueryOptions = {
  formOptions: {},
  isCleanQuery: true,
  withDebounce: true,
  onLoadingData: () => {},
  prepareTypedObj: val => val,
  debounceTime: 800
};

const useFiltersQuery = (opts?: Partial<FiltersQueryOptions>) => {
  const {
    withDebounce,
    formOptions,
    isCleanQuery,
    onBeforeSetQueryItems,
    onLoadingData,
    debounceTime,
    prepareTypedObj
  } = {
    ...defaultFiltersQueryOptions,
    ...(opts || {})
  };
  const route = useRoute();
  const router = useRouter();
  const loading = ref(false);

  const onFetch = async () => {
    if (loading.value) {
      return;
    }
    loading.value = true;
    onLoadingData?.();
    loading.value = false;
  };
  const onFetchDebounce = withDebounce ? useDebounce(onFetch, debounceTime) : onFetch;
  const initialValues = computed(() => unref(formOptions?.initialValues) || {});
  const onTypedObject = (val?: object) => {
    return prepareTypedObj(typedObject(initialValues.value, val));
  };
  const getInitialValues = (currentRouter: TypedRoute) => {
    const locationParams = qsParse(new URL('https://' + currentRouter.fullPath).search, {
      plainObjects: true,
      ignoreQueryPrefix: true
    });
    return onTypedObject(isCleanQuery ? getCleanFormValue(locationParams, initialValues.value) : locationParams);
  };
  const form = useForm({
    ...formOptions,
    initialValues: getInitialValues(route)
  });

  const isApplyFilter = computed(() => !isEqual(form.values, initialValues.value));

  const onUpdateQuery = (newQuery: object) => {
    router.push(
      '?' + qsStringify(cleanQueryParams(getCleanFormValue(newQuery, initialValues.value)), { skipNulls: true })
    );
  };

  const onResetFilter = () => {
    form.setValues(initialValues.value);
    onFetch();
  };

  const onSetValuesAndFetch = (values: TValues) => {
    form.setValues({
      ...form.values,
      ...values
    });
    onFetch();
  };

  watchDebounced(
    () => ({ ...form.values }),
    (values, oldValue) => {
      const prepareValues = onBeforeSetQueryItems?.(values, oldValue) || {};
      const newValues = { ...values, ...prepareValues };
      if (!isEqual(newValues, values)) {
        form.setValues(newValues);
        return;
      }
      onUpdateQuery(newValues);
    },
    {
      debounce: 50,
      deep: true
    }
  );
  onBeforeRouteUpdate(to => {
    const currentVal = getInitialValues(to as TypedRoute);
    if (!isEqual(currentVal, form.values)) {
      form.setValues({ ...form.values, ...currentVal });
      onFetchDebounce();
      return;
    }
    if (!isEqual(getInitialValues(route), form.values)) {
      onFetchDebounce();
    }
  });

  return {
    ...form,
    loading,
    isApplyFilter,
    onFetch,
    onSetValuesAndFetch,
    onResetFilter
  };
};

export default useFiltersQuery;
