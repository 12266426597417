<template>
  <div
    class="flex flex-col max-w-4xl mx-auto flex-1 w-full"
    :class="{ 'px-2.5 lg:px-6 py-7': isAuthPage }"
  >
    <common-headers-set-them
      v-if="isAuthPage"
      class="lg:block hidden ml-auto"
    />
    <div class="flex items-center justify-center max-w-4xl mx-auto w-full flex-1">
      <transition-scale mode="out-in">
        <slot
          v-if="!showSuccess"
          name="form"
          :on-success="handleSuccess"
        />
        <slot
          v-else
          name="success"
        />
      </transition-scale>
    </div>
  </div>
</template>

<script setup lang="ts">
  type Props = {
    success?: boolean;
    isAuthPage?: boolean;
  };
  const props = withDefaults(defineProps<Props>(), {
    isAuthPage: true
  });
  const emits = defineEmits(['update:success']);
  const showSuccess = useVModel(props, 'success', emits, {
    passive: true,
    eventName: 'update:success'
  });

  const handleSuccess = () => {
    showSuccess.value = true;
  };
</script>
