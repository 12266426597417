const useTextField = (props: TextareaFieldProps | TextFieldProps, emit: TextFiledEmit) => {
  const { value, errorMessage } = useField(() => props.name || '', undefined, {
    initialValue: props.modelValue,
    syncVModel: props.syncVModel,
    controlled: !props.enabledControlled
  });

  const setVal = (nevVal: string) => {
    value.value = nevVal;
  };

  const onInput = (event: Event) => {
    const val = (event.target as HTMLInputElement)?.value;
    if (!props.modelModifiers?.lazy) {
      setVal(val);
    }
    emit('input', val, event);
  };

  const onChange = (event: Event) => {
    const val = useTrim((event.target as HTMLInputElement)?.value);
    setVal(val);
    emit('change', val, event);
  };

  const hasValue = computed(() => !!useTrim(value.value).length);

  const onClear = () => {
    setVal('');
  };

  return {
    value,
    errorMessage,
    hasValue,
    onChange,
    onInput,
    onClear
  };
};

export { useTextField };
