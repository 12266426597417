<template>
  <vue-final-modal
    v-bind="$attrs"
    class="flex justify-center items-center"
    :content-class="[
      sizeClasses,
      contentClasses,
      'relative w-full max-h-[90%] min-h-[10rem] mx-4 bg-card overflow-y-auto rounded-lg shadow-nav flex flex-col'
    ]"
    :focus-trap="false"
    :esc-to-close="escToClose"
    @update:model-value="setVal"
  >
    <slot name="wrap">
      <div class="flex flex-col md:p-8 p-4 max-h-full overflow-auto">
        <div v-if="$slots.title || title">
          <slot name="title">
            <ui-typography
              v-if="title"
              tag="h3"
              :variant="variantTitle"
              class="mb-5 text-center font-medium"
              :class="classesTitle"
              :text="title"
            />
          </slot>
        </div>
        <slot :on-close="onClose">
          <div
            v-if="body"
            class="mb-5"
          >
            {{ $t(body) }}
          </div>
        </slot>
        <slot
          name="footer"
          :on-reject="onReject"
          :on-success="onSuccess"
          :on-close="onClose"
        />
      </div>
    </slot>
    <ui-button
      v-if="!hideBtnClose"
      class="!absolute top-3 right-4 z-20"
      variant="secondary-outline"
      size="md-icon"
      icon-left="close"
      @click="onClose()"
    />
    <slot name="loader">
      <ui-loader :loading="loading" />
    </slot>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';

  type Props = {
    body?: string;
    title?: string;
    hideBtnClose?: boolean;
    loading?: boolean;
    classesTitle?: ClassesType;
    contentClasses?: ClassesType;
    size?: 'md' | 'lg';
    escToClose?: boolean;
    variantTitle?: KVariantsTypography;
    providerKey?: symbol | string;
  };
  const props = withDefaults(defineProps<Props>(), {
    size: 'md',
    escToClose: true,
    variantTitle: 'h4'
  });

  const { setVal, onClose, onSuccess, onReject } = useModalState(props.providerKey);
  const sizeClasses = computed(() => {
    return {
      md: 'max-w-xl',
      lg: 'max-w-3xl'
    }[props.size];
  });
</script>
