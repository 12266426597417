<template>
  <div
    ref="wrapElem"
    class="ios-line-fix"
  >
    <ui-fields-switch
      v-model="isLight"
      enabled-controlled
      sync-v-model
      :checked-value="true"
      :unchecked-value="false"
      :badge-icon="isLight ? 'light_mode' : 'dark_mode'"
      name="switch"
    />
  </div>
</template>

<script setup lang="ts">
  const colorMode = useColorMode();
  const wrapElem = ref<HTMLDivElement>();

  const setMode = async (mode: string) => {
    colorMode.preference = mode;
    // if (
    //   !wrapElem.value ||
    //   !document.startViewTransition ||
    //   window.matchMedia('(prefers-reduced-motion: reduce)').matches
    // ) {
    //   colorMode.preference = mode;
    //   return;
    // }
    // await document.startViewTransition(() => {
    //   nextTick(() => {
    //     colorMode.preference = mode;
    //   });
    // }).ready;
    // if (wrapElem.value) {
    //   const { top, left, width, height } = wrapElem.value.getBoundingClientRect();
    //   const x = left + width / 2;
    //   const y = top + height / 2;
    //   const right = window.innerWidth - left;
    //   const bottom = window.innerHeight - top;
    //   const maxRadius = Math.hypot(Math.max(left, right), Math.max(top, bottom));
    //
    //   document.documentElement.animate(
    //     {
    //       clipPath: [`circle(0px at ${x}px ${y}px)`, `circle(${maxRadius}px at ${x}px ${y}px)`]
    //     },
    //     {
    //       duration: 800,
    //       easing: 'ease-in-out',
    //       pseudoElement: '::view-transition-new(root)'
    //     }
    //   );
    // }
  };

  const isLight = computed({
    get() {
      return colorMode.value === 'light';
    },
    set(val) {
      setMode(val ? 'light' : 'dark');
    }
  });
</script>
<style>
  /*noinspection CssInvalidPseudoSelector*/
  ::view-transition-old(root),
  ::view-transition-new(root) {
    animation: none;
    mix-blend-mode: normal;
  }
</style>
